import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwButton } from './BtwButton';
import { BtwToggle } from './BtwToggle';
import { cn } from './utils';
const dropdown = tv({
    slots: {
        trigger: [
        // Base styles for the dropdown button, aka trigger
        ],
        content: [
            // Base styles for the dropdown… dropdown
            'p-2.5',
            'rounded-xl',
            'bg-white',
            'shadow-xl',
            'ring-1',
            'ring-gray-600/10',
            'z-[1000]',
            'min-w-64',
        ],
        item: [
            // Base styles for the individual dropdown items.
            // Includes some overrides to the buttons, since the default styles
            // are a little too much for dropdown items.
            // Overriding the font weight here, because semibold is too much
            // for dropdown items
            'font-medium',
            // Overrides for the disabled state
            'disabled:bg-white',
            // Overrides for the focus state
            'focus-visible:ring-0',
            'focus-visible:ring-transparent',
            'focus-visible:outline-0',
            'focus-visible:outline-transparent',
            // Overrides for the hover state
            'hover:bg-transparent',
            'hover:ring-transparent',
            // Using the library's state to highlight hover/focus items.
            // This enables seamless switch between hover & keyboard interactions
            'data-[highlighted]:bg-gray-400/25',
        ],
    },
});
const { trigger, content, item } = dropdown();
function Trigger(props) {
    const { children, className, size = 'base', variant = 'ghost', width, testId = 'dropdown', leadingIcon, standaloneIcon, disabled, } = props;
    return (React.createElement(DropdownPrimitive.Trigger, { "data-testId": testId, asChild: true, disabled: disabled },
        React.createElement(BtwButton, { size: size, className: cn(`
        ${trigger({ className })}
    `), variant: variant, width: width, leadingIcon: leadingIcon && leadingIcon, standaloneIcon: standaloneIcon && standaloneIcon, disabled: disabled }, children)));
}
function Content(props) {
    const { children, className, testId = 'dropdown-content' } = props;
    return (React.createElement(DropdownPrimitive.Portal, null,
        React.createElement(DropdownPrimitive.Content, { className: content({ className }), "data-testid": testId, collisionPadding: 16, sideOffset: 8 }, children)));
}
function Item(props) {
    const { className, children, disabled, icon, isLoading, onSelect } = props;
    return (React.createElement(DropdownPrimitive.Item, { disabled: disabled, asChild: true, onSelect: onSelect },
        React.createElement(BtwButton, { leadingIcon: icon && icon, className: cn(`
            ${item({ className })}
            ${'justify-start'}
        `), width: "full", variant: "ghost", isLoading: isLoading }, children)));
}
function ToggleItem(props) {
    const { className, children, disabled, icon, onSelect, pressed } = props;
    return (React.createElement(DropdownPrimitive.Item, { disabled: disabled, asChild: true, onSelect: (event) => {
            if (onSelect) {
                onSelect(event);
            }
            event.preventDefault();
        } },
        React.createElement(BtwButton, { disabled: disabled, leadingIcon: icon && icon, className: cn(`
            ${item({ className })}
            ${'justify-start pr-2'}
        `), width: "full", variant: "ghost" },
            React.createElement("div", { className: "flex-1 text-left" }, children),
            React.createElement(BtwToggle, { "data-testid": props['data-testid'], disabled: disabled, pressed: pressed }))));
}
function Separator() {
    return (React.createElement(DropdownPrimitive.Separator, { className: "border-b border-gray-600/10" }));
}
export const BtwDropdown = {
    Container: DropdownPrimitive.Root,
    Trigger,
    Content,
    Item,
    ToggleItem,
    Separator,
};
