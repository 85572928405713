import { __rest } from "tslib";
import * as ProgressPrimitive from '@radix-ui/react-progress';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { cn } from './utils';
const progress = tv({
    base: [
        'w-full',
        'relative',
        'bg-gray-200',
        'rounded-full',
        'overflow-hidden',
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        'z-0',
    ],
    variants: {
        variant: {
            primary: ['[&>div]:bg-gray-900'],
            legacyBlue: ['[&>div]:bg-legacyBlue'],
            green: ['[&>div]:bg-green-500'],
            yellow: ['[&>div]:bg-yellow-500'],
            red: ['[&>div]:bg-red-500'],
        },
        size: {
            sm: ['h-1.5'],
            base: ['h-2'],
            md: ['h-3'],
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'base',
    },
});
function ProgressBase(props) {
    const { className, size, value, variant } = props, rest = __rest(props, ["className", "size", "value", "variant"]);
    return (React.createElement(ProgressPrimitive.Root, Object.assign({ className: progress({ className, size, variant }), value: value }, rest),
        React.createElement(ProgressPrimitive.Indicator, { className: cn('w-full', 'h-full', 'rounded-full', 
            // Transition
            'duration-1000', 'ease-smooth', 'transition-combined', 'will-change-transform'), style: { transform: `translateX(-${100 - value}%)` } })));
}
function AnimatedProgress(props) {
    const { value, delay = 500 } = props, rest = __rest(props, ["value", "delay"]);
    const [animated, setAnimated] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setAnimated(true);
        }, delay);
    }, [delay]);
    return React.createElement(ProgressBase, Object.assign({}, rest, { value: animated ? value : 0 }));
}
const Progress = Object.assign(ProgressBase, {
    Animated: AnimatedProgress,
});
export { Progress };
