import { range } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { fromUnixTime } from '@marvelapp/core';
import { SelectWithLabel } from './SelectWithLabel';
import { Stack } from './Stack';
const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export function DateDropdowns({ dateFormat, isDisabled = false, onChange, value, }) {
    const dateParts = dateFormat.split('-');
    const [date, setDate] = useState(() => getInitialDate(value));
    useEffect(() => {
        const { day, month, year } = date;
        const hasFullDate = day !== null && month !== null && year !== null;
        if (hasFullDate) {
            const newDate = Date.UTC(year, month, day);
            if (onChange)
                onChange(newDate / 1000);
        }
    }, [date, onChange]);
    return (React.createElement(Stack, { direction: { initial: 'col', sm: 'row' }, gap: "2", width: "full" }, dateParts.map((datePart, index) => (React.createElement(Stack, { "data-testid": "date-wrapper", width: "full", className: index === 1 ? 'basis-1/2' : 'basis-1/3' },
        React.createElement(DateDropdown, { date: date, datePart: datePart[0].toLowerCase(), disabled: isDisabled, setDate: setDate }))))));
}
function DateDropdown({ date, disabled, datePart, setDate, }) {
    const { day: selectedDay, month: selectedMonth, year: selectedYear } = date;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (datePart === 'y') {
        return (React.createElement(DateSelect, { id: "year", key: "year", disabled: disabled, label: "Year", onChange: (e) => setDate({
                day: selectedDay,
                month: selectedMonth,
                year: Number(e.target.value),
            }), placeholder: "----", selectedValue: selectedYear ? `${selectedYear}` : '', testId: "date-picker--year" }, range(1904, currentYear + 100).map((year) => {
            return (React.createElement("option", { selected: selectedYear === year, value: year }, year));
        })));
    }
    if (datePart === 'm') {
        return (React.createElement(DateSelect, { disabled: disabled, id: "month", key: "month", label: "Month", onChange: (e) => setDate({
                day: selectedDay,
                month: Number(e.target.value),
                year: selectedYear,
            }), placeholder: "------", selectedValue: selectedMonth !== null ? `${selectedMonth}` : '', testId: "date-picker--month" }, MONTHS.map((month, index) => (React.createElement("option", { value: index, selected: index === selectedMonth }, month)))));
    }
    if (datePart === 'd') {
        const endDate = getEndDate(selectedYear || currentYear, selectedMonth);
        return (React.createElement(DateSelect, { disabled: disabled, id: "day", key: "day", label: "Day", onChange: (e) => setDate({
                day: Number(e.target.value),
                month: selectedMonth,
                year: selectedYear,
            }), placeholder: "--", selectedValue: selectedDay ? `${selectedDay}` : '', testId: "date-picker--day" }, range(0, endDate).map((day) => (React.createElement("option", { selected: day === selectedDay }, day + 1)))));
    }
    return null;
}
function DateSelect({ children, disabled, id, label, onChange, placeholder, selectedValue, testId, }) {
    return (React.createElement(Stack, { width: "full", align: "stretch" },
        React.createElement(SelectWithLabel, { "data-testid": testId, disabled: disabled, id: id, label: label, onChange: onChange, selectSize: "l", value: selectedValue, mb: "xs" },
            React.createElement("option", { value: "", disabled: true, selected: true }, placeholder),
            children)));
}
function getEndDate(year, month) {
    if (!month)
        return 31;
    return new Date(year, month + 1, 0).getDate();
}
function getInitialDate(value) {
    if (typeof value === 'string') {
        return {
            day: null,
            month: null,
            year: null,
        };
    }
    // we use fromUnixTime here as it handles negative values
    const date = fromUnixTime(value);
    return {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
    };
}
