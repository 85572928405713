import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { ArchiveIcon, ArrowLeftIcon, BagIcon, BillIcon, CreditCardIcon, DotsIcon, FolderIcon, FolderUserIcon, LayoutGridIcon, LogoutIcon, MapIcon, MegaphoneIcon, PeopleBehindIcon, PeopleCircleIcon, RescueRingIcon, SettingsIcon, StoreIcon, } from '@marvelapp/ballpark-icons';
import theme from '@marvelapp/ballpark-theme';
import { BtwAvatar } from './BtwAvatar';
import { BtwButton } from './BtwButton';
import { BtwDropdown } from './BtwDropdown';
import { BtwText } from './BtwText';
import { SidebarBanner } from './SidebarBanner';
import { Stack } from './Stack';
import { cn } from './utils';
var SidebarPanel;
(function (SidebarPanel) {
    SidebarPanel["Main"] = "main";
    SidebarPanel["Settings"] = "settings";
})(SidebarPanel || (SidebarPanel = {}));
var SidebarNavItem;
(function (SidebarNavItem) {
    // Main sidebar menu
    SidebarNavItem["Main_AllProjects"] = "All projects";
    SidebarNavItem["Main_MyProjects"] = "My projects";
    SidebarNavItem["Main_Archive"] = "Archive";
    SidebarNavItem["Main_People"] = "People";
    SidebarNavItem["Main_Templates"] = "Templates";
    SidebarNavItem["Main_Help"] = "Help";
    SidebarNavItem["Main_Roadmap"] = "Roadmap";
    // Settings menu
    SidebarNavItem["Settings_Personal"] = "Personal";
    SidebarNavItem["Settings_Workspace"] = "Workspace";
    SidebarNavItem["Settings_PlanBilling"] = "Plan & Billing";
    SidebarNavItem["Settings_RecruitmentOrders"] = "Recruitment orders";
    SidebarNavItem["Settings_ConnectedApps"] = "Connected apps";
})(SidebarNavItem || (SidebarNavItem = {}));
function AppSidebar() {
    const [activePanel, setActivePanel] = useState(SidebarPanel.Main);
    const [activeMenuItem, setActiveMenuItem] = useState(SidebarNavItem.Main_AllProjects);
    return (React.createElement(Stack, { direction: "row", className: cn('relative h-full w-64 overflow-hidden bg-gray-50 ring-1 ring-gray-600/10') },
        React.createElement(AnimatePresence, { initial: false },
            activePanel === SidebarPanel.Main && (React.createElement(motion.div, { key: "sidebar-main", className: "absolute h-full", initial: { x: '-100%' }, animate: { x: 0 }, exit: {
                    x: '-100%',
                }, transition: { ease: theme.easings.smooth.array, duration: 0.2 } },
                React.createElement(MainSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel }))),
            activePanel === SidebarPanel.Settings && (React.createElement(motion.div, { key: "sidebar-settings", className: "absolute h-full", initial: { x: '100%' }, animate: { x: 0 }, exit: {
                    x: '100%',
                }, transition: { ease: theme.easings.smooth.array, duration: 0.2 } },
                React.createElement(SettingsSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel }))))));
}
function MainSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel } = props;
    return (React.createElement(Stack, { className: "h-full min-w-64" },
        React.createElement(UserSection, { setActivePanel: setActivePanel, setActiveMenuItem: setActiveMenuItem }),
        React.createElement(Stack, { width: "full" },
            React.createElement(Stack, { className: "px-3 pt-3", width: "full" },
                React.createElement(BtwButton, { variant: "secondary", width: "full" }, "Create new project"))),
        React.createElement(Stack, { width: "full", className: "h-full divide-y divide-gray-600/10" },
            React.createElement(SidebarMenu, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel }))));
}
function SettingsSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel } = props;
    return (React.createElement(Stack, { className: "h-full min-w-64" },
        React.createElement(Stack, { direction: "row", align: "center", width: "full", gap: "2", className: "h-[52px] border-b border-gray-600/10 px-5" },
            React.createElement(BtwButton, { variant: "ghost", size: "sm", standaloneIcon: React.createElement(ArrowLeftIcon, null), onClick: () => {
                    setActivePanel(SidebarPanel.Main);
                    setActiveMenuItem(SidebarNavItem.Main_AllProjects);
                } }),
            React.createElement(BtwText, { size: "sm", variant: "primary", weight: "medium" }, "Settings")),
        React.createElement(Stack, { width: "full", className: "p-3", gap: "0.5" }, settingsMenu.map((item) => {
            const { id, title, icon, url } = item;
            return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
        }))));
}
function UserSection(props) {
    const { setActivePanel, setActiveMenuItem } = props;
    return (React.createElement(Stack, { direction: "row", gap: "3", align: "center", width: "full", className: "h-[52px] shrink-0 border-b border-gray-600/10 px-3" },
        React.createElement(Stack, { className: "relative" },
            React.createElement(BtwAvatar, { name: user.name, size: "lg", src: user.avatarUrl }),
            React.createElement("img", { src: user.companyImageUrl, className: "absolute -bottom-px -right-1 size-4 rounded-sm ring-2 ring-gray-50", alt: user.companyName })),
        React.createElement(Stack, { width: "full" },
            React.createElement(BtwText, { variant: "primary", size: "13", weight: "medium", className: "leading-5" }, user.name),
            React.createElement(BtwText, { size: "xs", weight: "medium", variant: "secondary", className: "-mt-0.5" }, user.companyName)),
        React.createElement(Stack, null,
            React.createElement(BtwDropdown.Container, null,
                React.createElement(BtwDropdown.Trigger, { testId: "sidebar-more-actions", standaloneIcon: React.createElement(DotsIcon, null), size: "sm" }),
                React.createElement(BtwDropdown.Content, { className: "w-64 p-0" },
                    React.createElement(Stack, { className: "p-1.5" },
                        React.createElement(BtwDropdown.Item, { icon: React.createElement(SettingsIcon, null), onSelect: () => {
                                setActivePanel(SidebarPanel.Settings);
                                setActiveMenuItem(SidebarNavItem.Settings_Personal);
                            } }, "Settings"),
                        React.createElement(BtwDropdown.Item, { icon: React.createElement(MegaphoneIcon, null) }, "Release notes")),
                    React.createElement(BtwDropdown.Separator, null),
                    React.createElement(Stack, { className: "p-2.5" },
                        React.createElement(BtwDropdown.Item, { icon: React.createElement(LogoutIcon, null) }, "Log out")))))));
}
function SidebarMenu(props) {
    const { activeMenuItem, setActiveMenuItem } = props;
    return (React.createElement(Stack, { width: "full", justify: "between", className: "h-full" },
        React.createElement(Stack, { width: "full", className: "divide-y divide-gray-600/10" },
            React.createElement(Stack, { width: "full", className: "p-3", gap: "0.5" }, sidebarProjectsMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
            })),
            React.createElement(Stack, { width: "full", className: " p-3", gap: "0.5" }, otherItemsMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
            }))),
        React.createElement(Stack, { width: "full", className: "divide-y divide-gray-600/10" },
            React.createElement(Stack, { className: "p-3" },
                React.createElement(SidebarBanner, { title: "9 days left in your trial", description: "Upgrade early or book a personalised demo for your team.", progressValue: 9, progressMax: 14, actions: React.createElement(BtwButton, { size: "sm", variant: "primary" }, "Upgrade now") })),
            React.createElement(Stack, { width: "full", className: " p-3", gap: "0.5" }, helpMenu.map((item) => {
                const { id, title, icon, url } = item;
                return (React.createElement(MenuItem, { isActive: title === activeMenuItem, key: id, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }));
            })))));
}
// I opted to use custom components here instead of buttons, because I had to override
// too many things to make the buttons fit the sidebar nav.
function MenuItem(props) {
    const { icon, title, key, url, isActive, onClick } = props;
    const menuItemClasses = [
        'h-9',
        'w-full',
        'inline-flex',
        'flex-row',
        'gap-2',
        'justify-start',
        'px-3',
        'items-center',
        'rounded-md',
        // Hover styles
        'hover:bg-gray-200/50',
        // Active styles (on click)
        'active:bg-gray-200',
        'active:translate-y-px',
    ];
    const activeMenuItemClasses = [
        'bg-gray-200/50',
        '[&>span]:text-gray-900',
        '[&>div]:text-gray-900',
    ];
    return (React.createElement("a", { href: url, key: key, className: cn(menuItemClasses, isActive ? activeMenuItemClasses : ''), onClick: onClick },
        React.createElement("span", { className: "text-gray-700 [&>svg]:size-5" }, icon),
        React.createElement(BtwText, { className: "text-gray-700", size: "13", weight: "medium" }, title)));
}
export { AppSidebar, SidebarPanel, SidebarNavItem };
const sidebarProjectsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_AllProjects,
        icon: React.createElement(FolderIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_MyProjects,
        icon: React.createElement(FolderUserIcon, null),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Main_Archive,
        icon: React.createElement(ArchiveIcon, null),
        url: '#',
    },
];
const otherItemsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_People,
        icon: React.createElement(PeopleBehindIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Templates,
        icon: React.createElement(StoreIcon, null),
        url: '#',
    },
];
const helpMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_Help,
        icon: React.createElement(RescueRingIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Roadmap,
        icon: React.createElement(MapIcon, null),
        url: '#',
    },
];
const settingsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Settings_Personal,
        icon: React.createElement(PeopleCircleIcon, null),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Settings_Workspace,
        icon: React.createElement(BagIcon, null),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Settings_PlanBilling,
        icon: React.createElement(CreditCardIcon, null),
        url: '#',
    },
    {
        id: 3,
        title: SidebarNavItem.Settings_RecruitmentOrders,
        icon: React.createElement(BillIcon, null),
        url: '#',
    },
    {
        id: 4,
        title: SidebarNavItem.Settings_ConnectedApps,
        icon: React.createElement(LayoutGridIcon, null),
        url: '#',
    },
];
const user = {
    name: 'Alfred Bottleneck',
    avatarUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?&w=128&h=128&dpr=2&q=80',
    companyName: 'Acme Inc',
    companyImageUrl: 'https://images.unsplash.com/photo-1574169208507-84376144848b?&w=128&h=128&dpr=2&q=80',
};
