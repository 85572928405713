import { __rest } from "tslib";
import React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import * as Tooltip from './Tooltip';
export function IconWithTooltip(_a) {
    var { children, content } = _a, boxProps = __rest(_a, ["children", "content"]);
    return (React.createElement(IconWrap, Object.assign({ "data-testid": "icon-with-toolti[" }, boxProps),
        React.createElement(Tooltip.Root, null,
            React.createElement(Tooltip.Trigger, null, children),
            React.createElement(Tooltip.Content, { side: "top" },
                React.createElement(Tooltip.Arrow, { offset: 8 }),
                content))));
}
const IconWrap = styled(Box) `
  &:last-child {
    padding-right: ${(props) => props.lastChildPr !== undefined ? props.lastChildPr : props.pr};
  }
`;
