//
// Rating score -- the thing that renders the rating icons
//
import { range } from 'lodash-es';
import React from 'react';
import { tv } from 'tailwind-variants';
import { HeartFilledIcon, StarFilledIcon, ThumbsUpFilledIcon, } from '@marvelapp/ballpark-icons';
import { RatingType } from '@marvelapp/user-test-creator';
import { Stack } from '../Stack';
import { cn } from '../utils';
function RatingScore(props) {
    const { ratingType, maxRating, averageRating, size = 'base' } = props;
    // Creating an array for the total number in the rating scale to loop through and render icons for
    const items = range(maxRating);
    // We remove any decimals and use the whole number to know how many "filled" rating icons to render
    const ratingNumberWhole = Math.floor(averageRating);
    // And then we use the decimal number to know how much of the partially filled icon to fill
    const ratingNumberDecimal = Number((averageRating % ratingNumberWhole).toFixed(1));
    return (React.createElement(Stack, { className: cn('relative') },
        React.createElement(Stack, { direction: "row", gap: "1" }, items.map((value, index) => {
            // If the averageRating has a decimal, render a partially filled icon, which overlays a gray icon
            if (ratingNumberDecimal && index === ratingNumberWhole) {
                return (React.createElement(Stack, { key: value, className: "relative" },
                    React.createElement("div", { className: "absolute overflow-hidden", style: {
                            width: `${ratingNumberDecimal * 100}%`,
                        } },
                        React.createElement(RatingIcon, { size: size, ratingType: ratingType })),
                    React.createElement(RatingIcon, { size: size, ratingType: ratingType, isEmpty: true })));
            }
            // Render all icons in with correct colors. If the item in the array is bigger than
            // the whole rating number, make it gray, otherwise icons have a default color based on their type.
            return (React.createElement(RatingIcon, { ratingType: ratingType, key: value, size: size, isEmpty: index >= ratingNumberWhole }));
        }))));
}
//
// Rating icon
//
const ratingIcon = tv({
    base: 'shrink-0',
    variants: {
        size: {
            sm: 'w-4 h-4',
            base: 'h-5 w-5',
            lg: 'h-8 w-8',
        },
        fillColor: {
            gray: 'fill-gray-300',
            red: 'fill-red-500',
            legacyBlue: 'fill-legacyBlue',
            amber: 'fill-amber-400',
        },
    },
});
function RatingIcon(props) {
    const { ratingType, size, isEmpty } = props;
    switch (ratingType) {
        case RatingType.Hearts:
            return (React.createElement(HeartFilledIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'red',
                }) }));
        case RatingType.Thumbs:
            return (React.createElement(ThumbsUpFilledIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'legacyBlue',
                }) }));
        case RatingType.Stars:
            return (React.createElement(StarFilledIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'amber',
                }) }));
        // Ignoring Numerical, because we have no icons to show for it.
        case RatingType.Numerical:
        default:
            return null;
    }
}
export { RatingScore };
