import { StepClass, } from '../common';
export var FixedStep;
(function (FixedStep) {
    FixedStep["Welcome"] = "Welcome";
    FixedStep["Exit"] = "Exit";
})(FixedStep || (FixedStep = {}));
export var StudyStatus;
(function (StudyStatus) {
    StudyStatus["Unpublished"] = "UNPUBLISHED";
    StudyStatus["Scheduled"] = "SCHEDULED";
    StudyStatus["Active"] = "ACTIVE";
    StudyStatus["AwaitingReview"] = "AWAITING REVIEW";
    StudyStatus["Paused"] = "PAUSED";
    StudyStatus["Completed"] = "COMPLETED";
})(StudyStatus || (StudyStatus = {}));
export var PrototypeStepType;
(function (PrototypeStepType) {
    PrototypeStepType["Prototype"] = "Prototype";
    PrototypeStepType["FirstClick"] = "FirstClick";
    PrototypeStepType["FreeRoam"] = "FreeRoam";
})(PrototypeStepType || (PrototypeStepType = {}));
export var QuestionStepType;
(function (QuestionStepType) {
    /**
     * Date as a name, confused ajv, as it clashes with its internal understanding of what Date is
     * Naming it as QuestionDate fixes the issue
     */
    QuestionStepType["QuestionDate"] = "Date";
    QuestionStepType["Email"] = "Email";
    QuestionStepType["Numerical"] = "Numerical";
    QuestionStepType["Text"] = "Text";
})(QuestionStepType || (QuestionStepType = {}));
export var DateFormat;
(function (DateFormat) {
    DateFormat["DDMMYYYY"] = "DD-MM-YYYY";
    DateFormat["MMDDYYYY"] = "MM-DD-YYYY";
    DateFormat["YYYYMMDD"] = "YYYY-MM-DD";
})(DateFormat || (DateFormat = {}));
export var YesOrNoStepType;
(function (YesOrNoStepType) {
    YesOrNoStepType["YesNo"] = "YesNo";
    YesOrNoStepType["Legal"] = "Legal";
})(YesOrNoStepType || (YesOrNoStepType = {}));
export var IdentityOperator;
(function (IdentityOperator) {
    IdentityOperator["Is"] = "IS";
    IdentityOperator["IsNot"] = "ISNOT";
    IdentityOperator["IsOkay"] = "ISOKAY";
})(IdentityOperator || (IdentityOperator = {}));
export var OrdinalOperator;
(function (OrdinalOperator) {
    OrdinalOperator["IsLessThan"] = "ISLESSTHAN";
    OrdinalOperator["IsGreaterThan"] = "ISGREATERTHAN";
    OrdinalOperator["IsLessOrEqualTo"] = "ISLESSOREQUALTO";
    OrdinalOperator["IsGreaterOrEqualTo"] = "ISGREATEROREQUALTO";
})(OrdinalOperator || (OrdinalOperator = {}));
export var CollectionOperator;
(function (CollectionOperator) {
    CollectionOperator["Contains"] = "CONTAINS";
    CollectionOperator["DoesNotContain"] = "DOESNOTCONTAIN";
})(CollectionOperator || (CollectionOperator = {}));
export var Combinator;
(function (Combinator) {
    Combinator["And"] = "AND";
    Combinator["Or"] = "OR";
})(Combinator || (Combinator = {}));
export var MultipleSelectionMode;
(function (MultipleSelectionMode) {
    MultipleSelectionMode["Unlimited"] = "Unlimited";
    MultipleSelectionMode["ExactNumber"] = "ExactNumber";
    MultipleSelectionMode["Range"] = "Range";
})(MultipleSelectionMode || (MultipleSelectionMode = {}));
export { StepClass };
export var ScreenerType;
(function (ScreenerType) {
    ScreenerType["Screener"] = "Screener";
    ScreenerType["Introduction"] = "Introduction";
    ScreenerType["Declined"] = "Declined";
})(ScreenerType || (ScreenerType = {}));
