import { __awaiter } from "tslib";
import { uniqBy } from 'lodash-es';
export function supportsWebcamRecording() {
    // TODO: ideally we should also check if the browser supports a list of predefined
    // codecs, but MediaRecorder.isTypeSupported doesn't work well in Safari 15
    // and returns false for video/webm.
    var _a;
    // If you're testing this on development remember that
    // `navigator.mediaDevices.getUserMedia` is undefined on Safari Mobile
    // when the connection is not secured with https.
    return !!(window.MediaRecorder && ((_a = navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getUserMedia));
}
export function isWebcamPermissionGranted() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const permission = yield navigator.permissions.query({
                name: 'camera',
            });
            return permission.state === 'granted';
        }
        catch (_a) {
            return false;
        }
    });
}
export function isMicrophonePermissionGranted() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const permission = yield navigator.permissions.query({
                name: 'microphone',
            });
            return permission.state === 'granted';
        }
        catch (_a) {
            return false;
        }
    });
}
export function combineMediaStreams(stream1, stream2) {
    const newStream = new MediaStream();
    stream1
        .getTracks()
        .filter((track) => track.enabled)
        .forEach((track) => newStream.addTrack(track));
    stream2
        .getTracks()
        .filter((track) => track.enabled)
        .forEach((track) => newStream.addTrack(track));
    return newStream;
}
export function getVideoAndAudioDevices() {
    return __awaiter(this, void 0, void 0, function* () {
        const canEnumerateDevices = !!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices);
        if (!canEnumerateDevices) {
            return {
                video: [],
                audio: [],
            };
        }
        const devices = yield navigator.mediaDevices.enumerateDevices();
        const video = devices.filter((device) => 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        device.kind === 'videoinput' || device.kind === 'video');
        const audio = 
        // We use uniqBy here because certain browsers, like Chrome and Opera,
        // list the same microphone twice:
        // https://stackoverflow.com/a/53308831/63011
        uniqBy(devices.filter((device) => 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        device.kind === 'audioinput' || device.kind === 'audio'), (device) => device.groupId);
        return { video, audio };
    });
}
export function getVideoAndAudioDeviceIds(mediaStream) {
    let videoDeviceId;
    let audioDeviceId;
    mediaStream.getTracks().forEach((track) => {
        if (track.kind === 'video' && track.enabled) {
            videoDeviceId = track.getSettings().deviceId;
        }
        if (track.kind === 'audio' && track.enabled) {
            audioDeviceId = track.getSettings().deviceId;
        }
    });
    return { video: videoDeviceId, audio: audioDeviceId };
}
const CODECS = [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp8,opus',
    'video/webm;codecs=vp8',
];
export function getVideoMimeType() {
    if (typeof MediaRecorder.isTypeSupported === 'function') {
        // eslint-disable-next-line no-restricted-syntax
        for (const codec of CODECS) {
            if (MediaRecorder.isTypeSupported(codec))
                return codec;
        }
    }
    // isTypeSupported is not supported, let the browser choose the preferred codec
    return undefined;
}
export const supportedBrowsers = Object.freeze({
    Chrome: 91,
    Edge: 91,
    Opera: 77,
    Safari: 14,
    Firefox: 75,
});
