import React from 'react';
import { Helmet } from 'react-helmet';
import { WarningIcon } from '@marvelapp/ballpark-icons';
import { FullScreenPage } from '../FullScreenPage';
export function BaseError({ heading, headingMaxWidth = 500, subheading, subheadingMaxWidth = 420, testId, }) {
    return (React.createElement(FullScreenPage.Container, { noFooter: true },
        React.createElement(Helmet, null,
            React.createElement("title", null, heading)),
        React.createElement(FullScreenPage.Message, { description: subheading, descriptionMaxWidth: subheadingMaxWidth, heading: heading, headingMaxWidth: headingMaxWidth, headingTestId: testId, icon: React.createElement(WarningIcon, { className: "h-12 w-12 [&>path]:stroke-[3px]" }) })));
}
