import React from 'react';
import { useLocation } from 'react-router-dom';
import { Heading, Icon, Text } from '@marvelapp/ballpark-components';
import { CHECKBOX_TICK_ICON } from '@marvelapp/ballpark-theme';
import { deviceDetection } from '@marvelapp/core';
import { supportedBrowsers } from '@marvelapp/media-recording';
import styled from '@marvelapp/styled';
import { Flex, PinAll, PinBR, Relative } from '@marvelapp/ui';
import { ChromeIcon, EdgeIcon, FirefoxIcon, OperaIcon, SafariIcon, } from './browserIcons';
const BrowserCheck = () => {
    const { pathname } = useLocation();
    const isPageAccessibleOnMobile = isAccessibleOnMobile(pathname);
    // iOS / iPadOS
    if ((deviceDetection.isIPad || deviceDetection.isIOS) &&
        !isPageAccessibleOnMobile) {
        const isSupportedSafariVersion = deviceDetection.browserVersion &&
            getMajorVersion(deviceDetection.browserVersion) >=
                supportedBrowsers.Safari;
        if (isSupportedSafariVersion) {
            return (React.createElement(Container, null,
                React.createElement(Heading, { mt: "m" },
                    "Accessing your Ballpark account requires using a desktop device",
                    ' '),
                React.createElement(Text, { mt: "xs", maxWidth: 450 }, "Sign in using your computer to create and edit projects")));
        }
        return (React.createElement(Container, { "data-testid": "browser-check-message" },
            React.createElement(Heading, { mt: "m" }, "Please use the latest versions of Safari on mobile"),
            React.createElement(Text, { mt: "xs", maxWidth: 450 }, "Sorry, we only support the latest versions of Safari."),
            React.createElement(Text, { mt: "xs", maxWidth: 450 }, "Please update your OS to version 14 or above.")));
    }
    if (!isPageAccessibleOnMobile) {
        return (React.createElement(Container, { "data-testid": "browser-check-message" },
            React.createElement(Heading, { mt: "m" },
                "This page can only run in ",
                getBrowserList('or'),
                isPageAccessibleOnMobile || ' on desktop'),
            React.createElement(BrowserLinks, null),
            React.createElement(Text, { mt: "xs", maxWidth: 450 },
                "Sorry, we only support the latest versions of ",
                getBrowserList(),
                ' ',
                isPageAccessibleOnMobile || 'on desktop ',
                "at the moment."),
            React.createElement(Text, { mt: "xs", maxWidth: 450 }, "Please open the link in one of those to get started.")));
    }
    return (React.createElement(Container, { "data-testid": "browser-check-message" },
        React.createElement(Heading, { mt: "m" },
            "To access this page, please use ",
            getBrowserList('or')),
        React.createElement(BrowserLinks, null),
        React.createElement(Text, { mt: "xs", maxWidth: 450 },
            "Sorry, we only support the latest versions of ",
            getBrowserList(),
            " at the moment."),
        React.createElement(Text, { mt: "xs", maxWidth: 450 }, "Please open the link in one of those to get started.")));
};
export default BrowserCheck;
function getBrowserList(endingConjuction = 'and') {
    const browserNames = Object.keys(supportedBrowsers);
    const lastBrowser = browserNames.pop();
    return `${browserNames.join(', ')} ${endingConjuction} ${lastBrowser}`;
}
function getMinimumSupportedBrowserVersion() {
    // eslint-disable-next-line no-restricted-syntax
    for (const [browserName, version] of Object.entries(supportedBrowsers)) {
        if (deviceDetection[`is${browserName}`]) {
            return version;
        }
    }
    return Infinity;
}
function isAccessibleOnMobile(pathname) {
    const accessiblePathsOnMobile = [
        '/record/',
        '/preview/',
        '/login',
        '/auth',
        '/website-record',
    ];
    if (deviceDetection.isMobile) {
        return accessiblePathsOnMobile.some((path) => pathname.includes(path));
    }
    return true;
}
const browserProps = {
    Chrome: {
        icon: React.createElement(ChromeIcon, null),
        url: 'https://google.com/chrome',
    },
    Edge: {
        icon: React.createElement(EdgeIcon, null),
        url: 'https://microsoft.com/windows/microsoft-edge',
    },
    Opera: { icon: React.createElement(OperaIcon, null), url: 'https://www.opera.com/' },
    Safari: { icon: React.createElement(SafariIcon, null), url: 'https://apple.com/safari' },
    Firefox: { icon: React.createElement(FirefoxIcon, null), url: 'https://mozilla.org/firefox' },
};
function BrowserLinks() {
    const browsers = Object.keys(supportedBrowsers);
    return (React.createElement(Flex, { my: "m", direction: "row", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: 450 }, browsers.map((browser) => {
        const { icon, url } = browserProps[browser];
        return (React.createElement("a", { href: url, target: "_blank", rel: "noopener noreferrer", title: `Download ${browser}`, key: browser },
            React.createElement(Relative, { mr: browser === browsers[browsers.length - 1] ? '0' : 'm' },
                icon,
                React.createElement(IconWrapper, null,
                    React.createElement(Icon, { paths: CHECKBOX_TICK_ICON })))));
    })));
}
export function useIsBrowserSupported() {
    const { pathname } = useLocation();
    if (!deviceDetection.osVersion || !deviceDetection.browserVersion) {
        return false;
    }
    if (deviceDetection.isMobile) {
        // On mobile we only support certain routes, anything else is not responsive yet
        const accessibleOnMobile = isAccessibleOnMobile(pathname);
        if (!accessibleOnMobile) {
            return false;
        }
        // If it is iPadOS, we only support a recent version of Safari / OS.
        // (Until iPadOS16, Safari and the OS use the same version number. From 16
        // onwards, the OS comes back as MacOS with a different version number)
        if (deviceDetection.isIPad) {
            // We don't need to check the browser version, since every other browser
            // downloadable from the App Store is just a reskinned version of Safari Mobile
            return (getMajorVersion(deviceDetection.osVersion) >=
                supportedBrowsers.Safari ||
                // From iPadOS 16 onwards, the OS version is reported as MacOS
                deviceDetection.isMacOs);
        }
        // If it is iOS we only support a recent version of Safari / OS
        // (they both use the same version number)
        if (deviceDetection.isIOS) {
            // We don't need to check the browser version, since every other browser
            // downloadable from the App Store is just a reskinned version of Safari Mobile
            return (getMajorVersion(deviceDetection.osVersion) >= supportedBrowsers.Safari);
        }
    }
    // On Android and desktop we only support modern browsers
    return (getMajorVersion(deviceDetection.browserVersion) >=
        getMinimumSupportedBrowserVersion());
}
function getMajorVersion(version) {
    return parseInt(version.split('.')[0], 10);
}
const Container = styled(PinAll).attrs({
    bg: 'background',
}) `
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px;
`;
const IconWrapper = styled(PinBR).attrs({
    bg: 'grass9',
    color: 'background',
    border: '2px solid',
    borderColor: 'background',
    borderRadius: 'circle',
}) `
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -6px;
`;
