import { __awaiter } from "tslib";
/* GLOBALS __DEV__ */
// import useDarkMode from 'use-dark-mode';
import { ApolloProvider } from '@apollo/client';
import '@ballpark/tailwind/style.css';
import { get, merge } from 'lodash-es';
import 'minireset.css/minireset.css';
import pathToRegexp from 'path-to-regexp';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as ThemeUIProvider } from 'theme-ui';
import { ErrorBoundary } from '@marvelapp/ballpark-application';
import { BtwTooltip, UnhandledError } from '@marvelapp/ballpark-components';
import baseTheme from '@marvelapp/ballpark-theme';
import { ThemeProvider } from '@marvelapp/styled';
import AppContainer from '../components/AppContainer';
import createGqlClient from '../createGqlClient';
import { routingMap } from '../routingMap';
import { GlobalStyle } from '../theme/globalStyles';
import { getAuth } from '../utils/auth';
// This is a workaround for suppressing errors generated from styled-components.
// Our own wrapper library filters most of them, using shouldForwardProp, but
// for some reasons it doesn't work with components that are wrapped with styled()
// e.g. styled(Comp)``
// It's a temporary solution until we find a better one, so that we can focus on
// fixing the real warnings/errors and hopefully keep the console clear.
if (process.env.NODE_ENV === 'development') {
    const consoleError = console.error;
    const SUPPRESSED_WARNINGS = [
        'Warning: React does not recognize the `%s` prop on a DOM element.',
    ];
    console.error = function filterWarnings(msg, ...args) {
        // Some tests become flaky because for some reason you can't always call `.includes()` on msg
        const canCallIncludeOnMsg = typeof msg === 'string' || Array.isArray(msg);
        if (canCallIncludeOnMsg &&
            !SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
            consoleError(msg, ...args);
        }
    };
}
// Define all color modes
const modes = ['light', 'dark'];
export const LIGHT_MODE = modes[0];
export const DARK_MODE = modes[1];
// Merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) => merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
    shadows: get(baseTheme.shadows.modes, mode, baseTheme.shadows),
});
// Set up App
function App({ client, accessToken }) {
    // const darkmode = useDarkMode(true);
    const darkmode = { value: false };
    const theme = getTheme(darkmode.value ? modes[1] : modes[0]);
    return (React.createElement(StrictMode, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(ThemeUIProvider, { theme: theme },
                React.createElement(GlobalStyle, { bg: "background", color: "slate11", fontFamily: 0 }),
                React.createElement(BrowserRouter, null,
                    React.createElement(ErrorBoundary, { component: UnhandledError },
                        React.createElement(ApolloProvider, { client: client },
                            React.createElement(BtwTooltip.Provider, { skipDelayDuration: 0, delayDuration: 0 },
                                React.createElement(AppContainer, { accessToken: accessToken })))))))));
}
export default function createApp() {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAuth();
        // While we are getting the info we need, we can preload the view
        // that we will be showing
        // eslint-disable-next-line no-restricted-syntax
        for (const route of routingMap) {
            const regex = pathToRegexp(route.path, [], { end: false });
            if (regex.test(document.location.pathname)) {
                route.preload();
                break;
            }
        }
        const mountNode = document.getElementById('root');
        const root = createRoot(mountNode);
        const client = createGqlClient({ accessToken });
        root.render(React.createElement(App, { accessToken: accessToken, client: client }));
    });
}
createApp();
