var _a, _b, _c;
import { UAParser } from 'ua-parser-js';
const parser = new UAParser();
const result = parser.getResult();
const isIPad = parser.getDevice().model === 'iPad';
const isIPhone = parser.getDevice().model === 'iPhone';
export const deviceDetection = {
    browserVersion: result.browser.version,
    isIPad,
    isIPhone,
    isAndroid: result.os.name === 'Android',
    isIOS: result.os.name === 'iOS',
    isMobile: result.device.type === 'mobile' || result.device.type === 'tablet',
    isMacOs: result.os.name === 'Mac OS',
    isDesktop: !result.device.type,
    isChrome: ((_a = result.browser.name) === null || _a === void 0 ? void 0 : _a.startsWith('Chrome')) ||
        result.browser.name === 'Brave',
    isOpera: (_b = result.browser.name) === null || _b === void 0 ? void 0 : _b.startsWith('Opera'),
    isSafari: (_c = result.browser.name) === null || _c === void 0 ? void 0 : _c.includes('Safari'),
    isFirefox: result.browser.name === 'Firefox',
    isEdge: result.browser.name === 'Edge',
    isTablet: result.device.type === 'tablet',
    // Returns false if a tablet.
    isMobileOnly: result.device.type === 'mobile',
    // Windows, Android...
    osName: result.os.name,
    // 7
    osVersion: result.os.version,
    // Chrome, Safari...
    browserName: result.browser.name === 'Brave' ? 'Chrome' : result.browser.name,
    // Nexus 5
    mobileModel: result.device.model,
};
