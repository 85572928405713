import themeGet from '@styled-system/theme-get';
import { color, layout } from 'styled-system';
import styled from '@marvelapp/styled';
import Box from '../Box';
import { focusBg, focusBoxShadow, focusColor, hoverBg, hoverBoxShadow, hoverColor, } from '../customProperties';
export const SelectElement = styled(Box) `
  appearance: none;
  outline-color: transparent;
  outline-offset: 4px;
  outline-style: dashed;
  outline-width: 1px;
  position: relative;
  transition: all 400ms ${themeGet('easings.smooth.string')};
  color: ${(props) => props.showPlaceholder && themeGet('colors.silver')};

  &:hover {
    ${hoverBg};
    ${hoverBoxShadow};
    ${hoverColor};
  }

  &:focus {
    ${focusColor};
    ${focusBoxShadow};
    ${focusBg};
  }

  &::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  /* target Internet Explorer 9 to undo the custom arrow */
  @media screen and (min-width: 0\0) {
    select {
      background: none\9;
      padding: 5px\9;
    }
  }
`;
export const Arrow = styled(Box) `
  ${color};
  ${layout};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
`;
