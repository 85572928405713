import { __rest } from "tslib";
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { tv } from 'tailwind-variants';
import { CloseIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
import { cn } from './utils';
/**
 * Modal overlay
 */
const overlay = tv({
    base: [
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'max-sm:items-end',
        'z-[1000]',
        'fixed',
        'inset-0',
        'data-[state=open]:motion-safe:animate-in',
        'data-[state=open]:motion-safe:fade-in',
        'data-[state=open]:motion-safe:duration-300',
        'data-[state=closed]:motion-safe:animate-out',
        'data-[state=closed]:motion-safe:fade-out',
        'data-[state=closed]:motion-safe:duration-500',
    ],
    variants: {
        position: {
            full: [],
            center: ['items-center', 'justify-center'],
            right: ['items-end', 'justify-center'],
            left: ['items-start', 'justify-center'],
        },
        background: {
            blurred: ['bg-gray-200/50', 'backdrop-blur-md'],
            none: ['bg-transparent'],
        },
    },
    defaultVariants: {
        position: 'center',
        background: 'blurred',
    },
});
const Overlay = React.forwardRef(function Overlay(props, forwardedRef) {
    const { className, position, background, hasOverlay = true } = props, rest = __rest(props, ["className", "position", "background", "hasOverlay"]);
    const Comp = hasOverlay ? DialogPrimitive.Overlay : 'span';
    return (React.createElement(Comp, Object.assign({ className: overlay({ className, position, background }), ref: forwardedRef }, rest)));
});
/**
 * Modal content
 */
const content = tv({
    base: [
        'bg-white',
        'shadow-lg',
        'flex',
        'flex-col',
        'z-40',
        'relative',
        'outline-none',
        'focus-visible:outline-none',
        'data-[state=open]:motion-safe:animate-in',
        'data-[state=open]:motion-safe:fade-in',
        'data-[state=open]:motion-safe:duration-300',
        'data-[state=open]:motion-safe:ease-smooth',
        'data-[state=closed]:motion-safe:animate-out',
        'data-[state=closed]:motion-safe:fade-out',
        'data-[state=closed]:motion-safe:duration-500',
        'data-[state=closed]:motion-safe:ease-smooth',
    ],
    variants: {
        size: {
            lg: ['w-screen', 'max-w-lg'],
            full: ['w-screen', 'max-w-full'],
        },
        position: {
            center: [
                'max-w-lg',
                'max-h-[80vh]',
                'rounded-t-xl',
                'sm:rounded-xl',
                'overflow-hidden',
                'data-[state=open]:motion-safe:slide-in-from-bottom-10',
                'sm:data-[state=open]:motion-safe:zoom-in-95',
                'data-[state=closed]:motion-safe:slide-out-to-bottom-10',
                'sm:data-[state=closed]:motion-safe:zoom-out-95',
            ],
            full: [
                'top-0',
                'left-0',
                'w-full',
                'h-full',
                'data-[state=open]:motion-safe:slide-in-from-bottom-20',
                'data-[state=closed]:motion-safe:slide-out-to-bottom-20',
            ],
            right: [
                'top-0',
                'h-full',
                'right-0',
                'max-w-lg',
                'data-[state=open]:motion-safe:slide-in-from-right',
                'data-[state=closed]:motion-safe:slide-out-to-right',
            ],
            left: [
                'top-0',
                'h-full',
                'left-0',
                'max-w-lg',
                'data-[state=open]:motion-safe:slide-in-from-left',
                'data-[state=closed]:motion-safe:slide-out-to-left',
            ],
        },
    },
    defaultVariants: {
        size: 'lg',
        position: 'center',
    },
});
const contentInner = tv({
    base: ['flex', 'flex-col', 'overflow-auto', 'gap-8', 'flex-1'],
    variants: {
        hasScrolled: {
            true: [
                '[&>.header-with-description]:ring-1',
                '[&>.header-with-description]:ring-slate-600/10',
            ],
            false: [],
        },
        justify: {
            between: ['justify-between'],
        },
    },
});
const Content = React.forwardRef(function Content(props, forwardedRef) {
    const { asChild, children, contentClassName, hasOverlay, hasPortal = true, justify, overlay = 'blurred', overlayClassName, position, size, container } = props, rest = __rest(props, ["asChild", "children", "contentClassName", "hasOverlay", "hasPortal", "justify", "overlay", "overlayClassName", "position", "size", "container"]);
    const [hasScrolled, setHasScrolled] = React.useState(false);
    const handleScroll = (event) => {
        setHasScrolled(event.currentTarget.scrollTop > 0);
    };
    const Comp = asChild ? Slot : 'div';
    return (React.createElement(WithPortal, { container: container, hasPortal: hasPortal },
        React.createElement(Overlay, { background: overlay, className: overlayClassName, hasOverlay: hasOverlay, position: position },
            React.createElement(DialogPrimitive.Content, Object.assign({ className: content({ className: contentClassName, position, size }), ref: forwardedRef }, rest),
                React.createElement(Comp, { className: contentInner({ hasScrolled, justify }), onScroll: handleScroll }, children)))));
});
function WithPortal(props) {
    const { children, container, hasPortal } = props;
    if (hasPortal) {
        return (React.createElement(DialogPrimitive.Portal, { container: container === null ? undefined : document.getElementById('root-modal') }, children));
    }
    return React.createElement(React.Fragment, null, children);
}
/**
 * Modal close
 */
function CloseButton(props) {
    return (React.createElement(DialogPrimitive.Close, { asChild: true },
        React.createElement(BtwButton, Object.assign({ "data-testid": "close-button", size: "sm", variant: "ghost", className: "flex-shrink-0", standaloneIcon: React.createElement(CloseIcon, null) }, props))));
}
/**
 * Modal title
 */
const Title = React.forwardRef(function Title(props, forwardedRef) {
    return (React.createElement(DialogPrimitive.Title, { asChild: true, ref: forwardedRef },
        React.createElement(BtwHeading, Object.assign({ leading: "snug", size: "2xl" }, props))));
});
/**
 * Modal description
 */
const Description = React.forwardRef(function Description(props, forwardedRef) {
    return (React.createElement(DialogPrimitive.Description, { asChild: true, ref: forwardedRef },
        React.createElement(BtwText, Object.assign({ leading: "relaxed" }, props))));
});
function Header(props) {
    const { title, icon } = props;
    return (React.createElement("header", { className: "sticky top-0 w-full border-b border-gray-600/10" },
        React.createElement("div", { className: "flex items-center gap-1 rounded-t-xl bg-white/90 p-4 backdrop-blur-md" },
            React.createElement(Title, { className: "flex-1", size: "base" }, title),
            icon,
            React.createElement(CloseButton, null))));
}
function BigHeader(props) {
    const { closeButtonTestId = 'close-button', description, descriptionTestId = 'dialog-heading', onlyChild, title, titleTestId = 'dialog-description', } = props;
    return (React.createElement(Stack, { className: cn('header-with-description', 'transition-combined', 'ease-smooth', 'sticky', 'top-0', '-mb-4', 'rounded-t-xl', 'bg-white/90', 'py-4', 'pl-8', 'pr-4', 'backdrop-blur-md', 'duration-300', 'z-10', {
            'mb-4': onlyChild,
        }), direction: "row", gap: "4", justify: "between" },
        React.createElement(Stack, { gap: "2.5", className: "pt-4" },
            React.createElement(Title, { "data-testid": titleTestId, size: "xl" }, title),
            description && (React.createElement(Description, { "data-testid": descriptionTestId, size: "sm" }, description))),
        React.createElement(CloseButton, { "data-testid": closeButtonTestId })));
}
function Footer(props) {
    return (React.createElement("footer", { className: "sticky bottom-0 -mt-8 border-t border-gray-600/10" },
        React.createElement("div", { className: "flex items-center justify-end gap-3 bg-white/90 p-4 backdrop-blur-md sm:rounded-b-xl only:[&>*]:w-full" }, props.children)));
}
/**
 * Modal body
 */
const body = tv({
    base: ['flex', 'flex-col', 'pb-8'],
    variants: {
        size: {
            sm: ['px-6', 'gap-6'],
            base: ['px-8', 'gap-8'],
        },
    },
    defaultVariants: {
        size: 'base',
    },
});
function Body(props) {
    const { size, className } = props, rest = __rest(props, ["size", "className"]);
    return React.createElement("div", Object.assign({ className: body({ className, size }) }, rest));
}
const BtwModal = {
    BigHeader,
    Body,
    Close: DialogPrimitive.Close,
    CloseButton,
    Content,
    Description,
    Footer,
    Header,
    Overlay,
    Root: DialogPrimitive.Root,
    Title,
    Trigger: DialogPrimitive.Trigger,
};
export { BtwModal };
