import { __rest } from "tslib";
import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';
import { tv } from 'tailwind-variants';
/**
 * List
 */
const list = tv({
    base: [
        // Appearance
        'border',
        'border-600/10',
        'bg-gray-100',
        // Layout
        'h-10',
        'p-1',
        'gap-1',
        'rounded-lg',
        'inline-flex',
        // Repeated focus styles
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
    ],
    variants: {},
    defaultVariants: {},
});
const List = React.forwardRef(function Item(props, forwardedRef) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TabsPrimitive.List, Object.assign({ className: list({ className }), ref: forwardedRef }, rest)));
});
/**
 * Trigger
 */
const trigger = tv({
    base: [
        // Basic reset
        'appearance-none',
        'bg-transparent',
        'border-none',
        'cursor-pointer',
        'ring-1',
        'ring-transparent',
        'select-none',
        // Layout
        'py-0',
        'px-4',
        'h-8',
        'flex',
        'box-border',
        'rounded-md',
        'items-center',
        'justify-center',
        // Typography
        'text-sm',
        'font-body',
        'text-center',
        'no-underline',
        'tracking-normal',
        // Selected
        'aria-[selected=true]:bg-white',
        'aria-[selected=true]:shadow-sm',
        'aria-[selected=true]:font-semibold',
        'aria-[selected=true]:text-gray-900',
        'aria-[selected=true]:ring-gray-600/10',
        'aria-[selected=true]:hover:bg-gray-50',
        'aria-[selected=true]:hover:ring-gray-600/20',
        // Deselected
        'aria-[selected=false]:font-medium',
        'aria-[selected=false]:text-gray-600',
        'aria-[selected=false]:hover:bg-gray-200',
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
        'will-change-transform',
        // Repeated active styles
        'active:translate-y-px',
        'active:outline-transparent',
        // Repeated focus styles
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
        // It's easier and more maintainable to replicate the disabled state across
        // all variants, than to try and override create a custom one for each variant
        'disabled:bg-gray-200',
        'disabled:text-gray-500',
        'disabled:ring-transparent',
        'disabled:shadow-none',
        'disabled:cursor-not-allowed',
        'disabled:hover:translate-y-0',
        'disabled:active:translate-y-0',
    ],
    variants: {},
    defaultVariants: {},
});
const Trigger = React.forwardRef(function Item(props, forwardedRef) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TabsPrimitive.Trigger, Object.assign({ className: trigger({ className }), ref: forwardedRef }, rest)));
});
/**
 * Content
 */
/**
 * List
 */
const content = tv({
    base: [
        // Layout
        'rounded-lg',
        // Repeated focus styles
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
    ],
    variants: {},
    defaultVariants: {},
});
const Content = React.forwardRef(function Item(props, forwardedRef) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(TabsPrimitive.Content, Object.assign({ className: content({ className }), ref: forwardedRef }, rest)));
});
const BtwTabs = {
    Root: TabsPrimitive.Root,
    List,
    Trigger,
    Content,
};
export { BtwTabs };
