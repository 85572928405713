import React, { forwardRef } from 'react';
import { ArrowRightIcon, ExpandIcon, FileIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
const DownloadCsv = forwardRef(function DownloadCsv(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(FileIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Download CSV"));
});
const ViewAllResponses = forwardRef(function ViewAllResponses(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ ref: ref, size: "sm", trailingIcon: React.createElement(ArrowRightIcon, null), variant: "ghost" }, props), "View all responses"));
});
const ViewBreakdown = forwardRef(function ViewBreakdown(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(ExpandIcon, null), ref: ref, size: "sm", variant: "secondary" }, props), "View full breakdown"));
});
export const StepButtons = {
    DownloadCsv,
    ViewAllResponses,
    ViewBreakdown,
};
