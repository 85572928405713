import { __rest } from "tslib";
import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';
import { BtwButton } from '../BtwButton';
import { Stack } from '../Stack';
import { cn } from '../utils';
function Root(props) {
    return (React.createElement(Stack, { gap: "4", width: "full", asChild: true },
        React.createElement(TabsPrimitive.Root, Object.assign({}, props))));
}
function List(props) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    return (React.createElement("div", { className: "w-full px-8" },
        React.createElement(TabsPrimitive.List, Object.assign({ asChild: true }, rest),
            React.createElement(Stack, { direction: "row", gap: "1", justify: "center", width: "full", className: cn('p-1.5', 'border', 'rounded-lg', 'bg-gray-100', 'border-gray-600/10', className) }, children))));
}
function Trigger(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement(TabsPrimitive.Trigger, Object.assign({ asChild: true }, rest),
        React.createElement(BtwButton, { className: cn('bg-transparent', 'text-gray-900', 
            // Hover state
            'hover:bg-gray-400/25', 'hover:ring-gray-400/25', 'active:bg-gray-400/50', 'active:ring-transparent', 
            // Focus state
            'focus-visible:ring-gray-600', 
            // Active state
            'data-[state=active]:bg-white', 'data-[state=active]:text-gray-900', 'data-[state=active]:ring-gray-600/20', 'data-[state=active]:shadow-sm-longer', 'data-[state=active]:hover:bg-gray-50', 'data-[state=active]:hover:shadow-md', 'data-[state=active]:hover:ring-gray-600/40', 'data-[state=active]:active:bg-gray-100', 'data-[state=active]:active:ring-gray-600/25', 'data-[state=active]:active:shadow-sm-longer', className), variant: "base", size: "sm" }, children)));
}
function Content(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(TabsPrimitive.Content, Object.assign({ asChild: true }, rest),
        React.createElement(Stack, { width: "full" }, children)));
}
function FooterCta(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(Stack, Object.assign({ align: "center", className: cn('border-t', 'border-gray-200', 'py-6', className), width: "full" }, rest)));
}
const SummaryTabs = {
    Content,
    FooterCta,
    List,
    Root,
    Trigger,
};
export { SummaryTabs };
